import styled from "styled-components"
import { colors } from "../../constants/colors"

type StylesProps = {
    size: string
}

export const handleBodySize = (size: string): string => {
    switch (size) {
        case "small":
            return "160px"
        case "medium":
            return "290px"
        case "auto":
            return "auto"
        default:
            return "290px"
    }
}

export const StyledTooltip = styled.span<StylesProps>`
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    * {
        box-sizing: border-box;
    }

    &:hover .textWrapperWhite {
        display: flex;
        display: -webkit-flex;
        animation: show 300ms linear forwards;
    }

    .arrowWhite {
        display: block;
        height: 14px;
        margin: 0;
        position: absolute;
        top: -10px;
        width: 12px;

        &:before,
        &:after {
            border-color: transparent;
            border-style: solid;
            content: "";
            display: block;
            position: absolute;
        }

        &:before {
            border-width: 0 10px 10px 10px;
            border-bottom-color: #bdbdbd;
            top: 0;
        }

        &:after {
            border-width: 0 10px 10px 10px;
            border-bottom-color: ${colors.white};
            top: 1px;
        }
    }

    .textWrapperWhite {
        bottom: -12px;
        height: 0;
        width: 0;
        align-items: center;
        display: none;
        flex-direction: column;
        position: absolute;
        top: initial;
        z-index: 9;

        a {
            color: ${colors.blue} !important;
        }
    }

    .titleWhite {
        margin-bottom: 6px;
        text-transform: capitalize;
    }

    .title {
        text-transform: lowercase;
    }

    .title > div {
        font-weight: 600;
        text-decoration: none;
    }

    .title > p {
        font-weight: 600;
        text-decoration: none;
        color: ${colors.black};
    }
    .title::first-letter {
        text-transform: uppercase;
    }

    .textWhite {
        background-color: ${colors.white};
        border: 1px solid ${colors.lightGrey};
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        line-height: 20px;
        padding: 5px 15px;
        width: ${({ size }) => handleBodySize(size)};
        p {
            margin: 0 0 6px 0;
        }

        img {
            width: 100%;
        }
    }

    .textWhiteTitle {
        font-style: italic;
        font-weight: bold;
        padding-bottom: 5px;

        p {
            font-weight: 600 !important;
            font-size: 16px !important;
            line-height: 24px !important;
        }

        & + span * {
            font-size: 14px !important;
            font-weight: 400 !important;
            line-height: 20px !important;
        }
    }

    .btnClose {
        appearance: none;
        background-color: transparent;
        border: none;
        display: none;

        img {
            height: 16px;
            width: 16px;
        }
    }

    @keyframes show {
        0% {
            opacity: 0;
            visibility: hidden;
        }
        100% {
            opacity: 1;
            visibility: visible;
        }
    }

    @media (max-width: 760px) {
        .textWhite {
            bottom: 0;
            left: 0;
            padding-top: 6px;
            position: fixed;
            top: initial;
            width: 100%;
            margin: 0;
            box-shadow: 0 0 50px rgb(0 0 0 / 35%);
            padding-right: 35px;
        }
        .arrowWhite {
            display: none;
        }

        .btnClose {
            position: absolute;
            display: block;
            top: 10px;
            right: 10px;
        }
    }
`
