import styled from "styled-components"

export const StyledConfirmShareExitModal = styled.div`
    text-align: center;

    .buttonsBlock {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        & > * {
            width: 45%;
        }
    }
`
