import { addModal, removeModal } from "../../../../redux/actions/ui/ui"
import {
    RIDDLE_MODAL_ANSWER_TWO,
    RIDDLE_MODAL_QUESTION_THREE,
    RIDDLE_MODAL_QUESTION_TWO,
} from "../../../../constants/modals"
import { useDispatch } from "react-redux"
import RiddlesModalQuestionThree from "./RiddlesModalQuestionThree"
import MathJax from "react-mathjax"
import ShareWithButtons from "./ShareWithButtons"

//styles
import { StyledRiddle, StyledRiddleTitle } from "./Riddles.styles"
import { StyledBodyMain, StyledH3 } from "../../../../styles/Text/Text.styles"
import Pagination from "./Pagination"
import { useAnswers } from "./Riddles.helpers"
import RiddlesModalQuestionTwo from "./RiddlesModalQuestionTwo"
import { useRiddleExit } from "../../../../hooks/useRiddleExit"

const RiddlesModalAnswerTwo = () => {
    const answers = useAnswers()

    const dispatch = useDispatch()

    const [onExit] = useRiddleExit()

    const onNextClick = () => {
        dispatch(removeModal(RIDDLE_MODAL_ANSWER_TWO))
        dispatch(
            addModal({
                id: RIDDLE_MODAL_QUESTION_THREE,
                children: <RiddlesModalQuestionThree />,
                hideLogo: true,
                hideCross: true,
                exitOnClickOutside: true,
                exitAction: onExit,
            })
        )
    }

    return (
        <StyledRiddle>
            <Pagination
                riddleNumber={2}
                state="answer"
                currentModalId={RIDDLE_MODAL_ANSWER_TWO}
                prevModalId={RIDDLE_MODAL_QUESTION_TWO}
                prevModalChildren={<RiddlesModalQuestionTwo />}
                nextModalId={RIDDLE_MODAL_QUESTION_THREE}
                nextModalChildren={<RiddlesModalQuestionThree />}
            />
            <MathJax.Provider options={{ messageStyle: "none", tex2jax: { preview: "none" } }}>
                <div className="marginTop">
                    <StyledRiddleTitle type="answer">
                        Riddles - How good is your intuition when it comes to probabilities?
                    </StyledRiddleTitle>
                </div>
                <div className="marginTop">
                    <StyledH3 weight={700}>Boxes and Balls I</StyledH3>
                </div>

                <div className="resultBox marginTop">
                    <div>Your estimate: {answers[2]}%</div>
                    <div className="resultString">
                        Actual answer: <StyledBodyMain weight={700}>99.9%</StyledBodyMain>
                    </div>
                    <div>that it’s Box A (with mostly white balls).</div>
                </div>

                <div className="marginTop marginBottom">
                    <StyledBodyMain weight={700}>How do we get to this answer?</StyledBodyMain>
                </div>

                <div>
                    <p>
                        Using{" "}
                        <a
                            href="https://www.wikiwand.com/en/Bayes%27_theorem"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Bayes’ theorem
                        </a>{" "}
                        (like in the previous riddle) plus{" "}
                        <a
                            href="https://www.wikiwand.com/en/Binomial_distribution"
                            target="_blank"
                            rel="noreferrer"
                        >
                            binomial distribution
                        </a>
                        .
                    </p>
                </div>

                <div className="marginTop marginBottom">
                    <StyledBodyMain weight={700} color="grey">
                        Full calculation:
                    </StyledBodyMain>
                </div>

                <MathJax.Node formula={"P(Box A) = P(Box B) = \\frac{1}{2}"} />
                <MathJax.Node formula={"P(Box A | 30\\;of\\;50\\;are\\;white)"} />
                <MathJax.Node formula={"="} />
                <div className="overflowAuto">
                    <MathJax.Node
                        formula={
                            "\\frac{\\begin{pmatrix} 50  \\\\ 30 \\end{pmatrix} (P(white | Box A))^{30}  (P(black | Box A))^{20}P(Box A) } { \\begin{pmatrix} 50  \\\\ 30 \\end{pmatrix} (P(white | Box A))^{30}(P(black | Box A))^{20} P(Box A) +}"
                        }
                    />
                    <MathJax.Node
                        formula={
                            "+\\begin{pmatrix} 50  \\\\ 30 \\end{pmatrix} (P(white | Box B))^{30}(P(black | Box B))^{20}P(Box B)"
                        }
                    />
                    <MathJax.Node formula={"="} />
                    <MathJax.Node
                        formula={
                            "\\frac{\\begin{pmatrix} 50  \\\\ 30 \\end{pmatrix}    \\begin{pmatrix}\\frac{2} {3}\\end{pmatrix}^{30}    \\begin{pmatrix}\\frac{1} {3}\\end{pmatrix}^{20}}    {\\begin{pmatrix} 50  \\\\ 30 \\end{pmatrix}       \\begin{pmatrix}\\frac{2} {3}\\end{pmatrix}^{30}    \\begin{pmatrix}\\frac{1} {3}\\end{pmatrix}^{20}    +   \\begin{pmatrix} 50  \\\\ 30 \\end{pmatrix}        \\begin{pmatrix}\\frac{1} {3}\\end{pmatrix}^{30}           \\begin{pmatrix}\\frac{2} {3}\\end{pmatrix}^{20}   }"
                        }
                    />
                </div>
                <MathJax.Node formula={"="} />
                <MathJax.Node formula={"\\frac{2^{10}}{2^{10}+1}"} />
                <MathJax.Node formula={"="} />
                <MathJax.Node formula={"99.9\\%"} />

                <ShareWithButtons
                    twitterTitle="How's your intuition when it comes to probability? Find out with these 4 riddles. @Rootclaim beats intuition."
                    onSubmit={onNextClick}
                />
            </MathJax.Provider>
        </StyledRiddle>
    )
}

export default RiddlesModalAnswerTwo
