import { StyledPagination, StyledPaginationItem } from "./Riddles.styles"
import Image from "next/image"
import { useDispatch } from "react-redux"
import { addModal, removeModal } from "../../../../redux/actions/ui/ui"
import { useAnswers } from "./Riddles.helpers"
import { useRiddleExit } from "../../../../hooks/useRiddleExit"

type Props = {
    riddleNumber: number
    state: "question" | "answer"
    nextModalId?: string
    nextModalChildren?: React.ReactNode
    prevModalId?: string
    prevModalChildren?: React.ReactNode
    currentModalId?: string
}

const Pagination = ({
    riddleNumber,
    state,
    prevModalId,
    prevModalChildren,
    currentModalId,
    nextModalId,
    nextModalChildren,
}: Props) => {
    const answers = useAnswers()
    const dispatch = useDispatch()

    const [onExit] = useRiddleExit()

    const onPrevClick = () => {
        currentModalId && dispatch(removeModal(currentModalId))
        prevModalId &&
            dispatch(
                addModal({
                    id: prevModalId,
                    children: prevModalChildren,
                    hideLogo: true,
                    hideCross: true,
                    exitOnClickOutside: true,
                    exitAction: onExit,
                })
            )
    }

    const onNextClick = () => {
        currentModalId && dispatch(removeModal(currentModalId))
        nextModalId &&
            dispatch(
                addModal({
                    id: nextModalId,
                    children: nextModalChildren,
                    hideLogo: true,
                    hideCross: true,
                    exitOnClickOutside: true,
                    exitAction: onExit,
                })
            )
    }

    const showNextButton = !!nextModalId && answers[riddleNumber] !== null

    return (
        <StyledPagination>
            {!!prevModalId && (
                <div className="arrowBack">
                    <Image src="/icons/arrowLeft.svg" width={9} height={14} onClick={onPrevClick} />
                </div>
            )}
            {[1, 2, 3, 4].map((pageNumber) => (
                <StyledPaginationItem
                    state={state}
                    active={riddleNumber === pageNumber}
                    key={pageNumber}
                >
                    {pageNumber}
                </StyledPaginationItem>
            ))}
            {showNextButton && (
                <div className="arrowNext">
                    <Image src="/icons/arrowLeft.svg" width={9} height={14} onClick={onNextClick} />
                </div>
            )}
        </StyledPagination>
    )
}

export default Pagination
