import { StyledTooltip } from "./TooltipLight.styles"
import { StyledBodyMain, StyledSubText } from "../../styles/Text/Text.styles"
import React, { useState } from "react"

type Props = {
    text: string | React.ReactNode
    title: string | React.ReactNode
    size: "small" | "medium" | "auto"
    hideTitle?: boolean
    customTitle?: string | React.ReactNode
    rtl?: boolean
}

const TooltipLight = ({ text, title, size, hideTitle, customTitle, rtl }: Props) => {
    const [show, setShow] = useState(false)

    return (
        <StyledTooltip size={size}>
            <span
                className="previewTitleWhite"
                onMouseEnter={() => setShow(true)}
                onTouchStart={() => setShow(true)}
            >
                {title}
            </span>
            {show && (
                <span className="textWrapperWhite">
                    <span className="arrowWhite" />
                    <span className="textWhite d-block">
                        <span className="textWhiteTitle">
                            {!hideTitle &&
                                (customTitle ? (
                                    customTitle
                                ) : (
                                    <StyledBodyMain className="title" weight={600} rtl={rtl}>
                                        {title}
                                    </StyledBodyMain>
                                ))}
                        </span>
                        <StyledSubText rtl={rtl}>{text}</StyledSubText>
                        <button type="button" className="btnClose" onClick={() => setShow(false)}>
                            <img alt="icon" src="/icons/cross.svg" width={16} height={16} />
                        </button>
                    </span>
                </span>
            )}
        </StyledTooltip>
    )
}

export default TooltipLight
