import MathJax from "react-mathjax"
import { useDispatch } from "react-redux"
import { addModal, removeModal } from "src/redux/actions/ui/ui"
import {
    RIDDLE_MODAL_ANSWER_ONE,
    RIDDLE_MODAL_QUESTION_ONE,
    RIDDLE_MODAL_QUESTION_TWO,
} from "src/constants/modals"
import { useAnswers } from "./Riddles.helpers"

import { useRiddleExit } from "src/hooks/useRiddleExit"

// components
import RiddlesModalQuestionOne from "./RiddlesModalQuestionOne"
import RiddlesModalQuestionTwo from "./RiddlesModalQuestionTwo"
import ShareWithButtons from "./ShareWithButtons"
import TooltipDark from "src/reusableComponents/TooltipDark"
import Pagination from "./Pagination"

//styles
import { StyledRiddle, StyledRiddleTitle } from "./Riddles.styles"
import { StyledBodyMain, StyledH3 } from "src/styles/Text/Text.styles"

const RiddlesModalAnswerOne = () => {
    const answers = useAnswers()

    const [onExit] = useRiddleExit()

    const dispatch = useDispatch()
    const onNextClick = () => {
        dispatch(removeModal(RIDDLE_MODAL_ANSWER_ONE))
        dispatch(
            addModal({
                id: RIDDLE_MODAL_QUESTION_TWO,
                children: <RiddlesModalQuestionTwo />,
                hideLogo: true,
                hideCross: true,
                exitOnClickOutside: true,
                exitAction: onExit,
            })
        )
    }

    return (
        <StyledRiddle>
            <Pagination
                riddleNumber={1}
                state="answer"
                prevModalId={RIDDLE_MODAL_QUESTION_ONE}
                prevModalChildren={<RiddlesModalQuestionOne />}
                currentModalId={RIDDLE_MODAL_ANSWER_ONE}
                nextModalId={RIDDLE_MODAL_QUESTION_TWO}
                nextModalChildren={<RiddlesModalQuestionTwo />}
            />
            <MathJax.Provider options={{ messageStyle: "none", tex2jax: { preview: "none" } }}>
                <div className="marginTop">
                    <StyledRiddleTitle type="answer">
                        Riddles - How good is your intuition when it comes to probabilities?
                    </StyledRiddleTitle>
                </div>
                <div className="marginTop">
                    <StyledH3 weight={700}>HIV test</StyledH3>
                </div>

                <div className="resultBox marginTop">
                    <div>Your estimate: {answers[1]}%</div>
                    <div className="resultString">
                        Actual answer: <StyledBodyMain weight={700}>26%</StyledBodyMain>
                    </div>

                    <div>that the person actually has HIV.</div>
                </div>

                <div className="marginTop marginBottom">
                    <StyledBodyMain weight={700}>How do we get to this answer?</StyledBodyMain>
                </div>
                <div className="marginBottom">
                    Using{" "}
                    <a
                        href="https://www.wikiwand.com/en/Bayes%27_theorem"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Bayes’ theorem
                    </a>
                    .
                </div>
                <div className="marginBottom">
                    There are 36.9 million people with HIV, and approximately 7 billion people in
                    the world.
                    <a
                        className="link"
                        href="http://www.wikiwand.com/en/Diagnosis_of_HIV/AIDS#/Accuracy_of_HIV_testing"
                        target="_blank"
                        rel="noreferrer"
                    >
                        [2]
                    </a>
                </div>
                <div>
                    <p>
                        Since there are exactly two competing hypotheses in this riddle (either the
                        person has HIV, or he doesn’t have HIV), the appropriate form of Bayes’
                        theorem to solve it is:
                    </p>
                </div>

                <div className="marginTop">
                    <MathJax.Node
                        formula={
                            "P(A | B) =\\frac{P(B|A)*P(A)}{P(B | A) * P(A) + P(B | not A) * P(not A)}"
                        }
                    />
                </div>

                <div className="marginTop marginBottom">
                    <div className="withTooltip">
                        <StyledBodyMain weight={700} color="grey">
                            How do I read these probability symbols?
                        </StyledBodyMain>
                        <TooltipDark>
                            <MathJax.Node
                                formula={
                                    "P(A | B) =\\frac{P(B|A)*P(A)}{P(B | A) * P(A) + P(B | not A) * P(not A)}"
                                }
                            />
                            <p>Probability notation key:</p>
                            <div>P(A) = The probability of event A</div>
                            <div>P(B) = The probability of event B</div>
                            <div>P(A | B) = The probability of event A, given event B</div>
                            <div>P(B | A) = The probability of event B, given event A</div>
                            <div>
                                P(A | not B) = The probability of event A, given "not event B"
                            </div>
                            <div>
                                P(B | not A) = The probability of event B, given "not event A"
                            </div>
                        </TooltipDark>
                    </div>
                </div>

                <div>
                    <p>
                        Plugging the information from the riddle into Bayes’ theorem gives us the
                        following:
                    </p>
                </div>

                <div className="marginTop">
                    <MathJax.Node formula={"P(has HIV | Test positive)"} />

                    <MathJax.Node formula={"="} />
                    <div className="overflowAuto">
                        <MathJax.Node
                            formula={
                                "\\frac{P(Test positive | has HIV) * P(has HIV)}{P(Test positive | has HIV) * P(has HIV) + P(Test positive | not has HIV) * P(not has HIV)}"
                            }
                        />
                    </div>

                    <MathJax.Node formula={"="} />
                    <div className="overflowAuto">
                        <MathJax.Node
                            formula={
                                "\\frac{99.7\\% * 0.53\\%}{99.7\\% * 0.53\\% + 1.5\\% * (1 - 0.53\\%)}"
                            }
                        />
                    </div>
                    <MathJax.Node formula={"="} />
                    <MathJax.Node formula={"26\\%"} />
                </div>

                <div className="marginTop marginBottom">
                    <StyledBodyMain weight={700}>How can this be?</StyledBodyMain>
                </div>
                <div className="marginBottom">
                    What most people forget to consider is the fact that HIV is rare in the
                    population in general, so the chance of a random person having it is already
                    very low. In fact, if everyone in the world were tested there would be more
                    false positives than actual cases of HIV!
                </div>
                <div>
                    <p>
                        Think about a hypothetical “aliens did it” theory. If you only saw one piece
                        of evidence (say, a photograph of a UFO), would that be enough to convince
                        you that it was aliens? Probably not, since the general prior plausibility
                        that “aliens did it” is so low. You would need much stronger evidence to
                        outweigh the extremely low starting likelihood.
                    </p>
                </div>

                <div className="marginTop marginBottom">
                    <StyledBodyMain weight={700}>
                        So why do we even use HIV tests at all?
                    </StyledBodyMain>
                </div>
                <div className="marginBottom">
                    If the person taking the test was not chosen randomly, but was sent by a doctor
                    due to the existence of other symptoms and/or environmental factors that often
                    indicate HIV, then the presence of all these factors combined with the positive
                    HIV test result means that there is a much higher likelihood that the person
                    does in fact have HIV. In addition, today doctors will generally only inform the
                    patient of a positive result after the lab has already run the test twice,
                    exactly for the reason demonstrated here: a single positive result is a poor
                    indicator, but two positive results means the patient is much more likely to
                    actually have HIV.
                </div>
                <div>
                    <p>
                        Similarly, if we combine a lot of different pieces of evidence together, we
                        may come to a much stronger conclusion than if we just look at each one
                        individually.
                    </p>
                </div>

                <ShareWithButtons
                    twitterTitle="How's your intuition when it comes to probability? Find out with these 4 riddles. @Rootclaim beats intuition."
                    onSubmit={onNextClick}
                />
            </MathJax.Provider>
        </StyledRiddle>
    )
}

export default RiddlesModalAnswerOne
