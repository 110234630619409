import { useDispatch } from "react-redux"
import { SyntheticEvent } from "react"
import { addModal } from "../redux/actions/ui/ui"
import { CONFIRM_RIDDLE_EXIT } from "../constants/modals"
import ConfirmRiddleExitModal from "../components/Header/Modals/Ridlles/ConfirmRiddleExitModal"

export const useRiddleExit = () => {
    const dispatch = useDispatch()
    const onExit = (e?: SyntheticEvent) => {
        e && e.preventDefault()
        dispatch(
            addModal({
                id: CONFIRM_RIDDLE_EXIT,
                children: <ConfirmRiddleExitModal />,
                hideCross: true,
                hideLogo: true,
                size: "small",
            })
        )
    }

    return [onExit]
}
