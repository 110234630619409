import styled from "styled-components"
import { colors } from "../../../../constants/colors"

export const StyledRiddle = styled.div`
    margin: 0 3%;

    @media (max-width: 575px) {
        padding-bottom: 46px;
    }

    p {
        font-size: 16px;
    }

    .marginTop {
        margin-top: 16px;
        font-size: 16px;
    }

    .textWrapper.tooltipTextWrapper {
        right: unset;
        align-items: center;
    }

    .smallMarginBottom {
        margin-bottom: 0;
    }

    .marginBottom {
        margin-bottom: 16px;
        font-size: 16px;
    }

    .alignCenter {
        text-align: center;

        > p {
            font-size: 16px;
        }
    }

    .mjx-chtml > span {
        font-size: 14px;
        font-style: normal;
    }

    .mjx-chtml.MJXc-display {
        margin: 7px 0;
    }

    .resultBox {
        background: ${colors.background};
        max-width: 100%;
        width: max-content;

        border: 1px solid ${colors.lineGrey};
        box-sizing: border-box;
        border-radius: 10px;

        padding: 20px;
        font-size: 16px;

        & > *:nth-child(2) {
            margin-top: 12px;
            margin-bottom: 12px;
        }

        .resultString {
            p {
                display: inline;
            }
        }
    }

    .redStar {
        color: ${colors.green};
    }

    .lastCancelButton {
        width: 176px;
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }

    .withTooltip {
        display: flex;
        align-items: center;

        .tooltipTextWrapper {
            margin-left: -50px;

            @media (max-width: 575px) {
                margin-left: 0;
                width: 100%;
            }
        }
        .arrow {
            transform: translate(54px, 0px);
        }
        .icon {
            margin-top: -5px;

            img {
                width: 18px;
                height: 18px;
            }
        }

        > p {
            margin-top: 2px;
            font-size: 14px;
        }
    }

    .link {
        position: relative;
        left: 3px;
        font-size: 12px;
        vertical-align: super;
        unicode-bidi: isolate;
    }

    .hint {
        cursor: pointer;
    }

    @media (max-width: 575px) {
        margin: 0;

        .lastCancelButton {
            padding-bottom: 20px;
        }

        @media not all and (min-resolution: 0.001dpcm) {
            .lastCancelButton {
                padding-bottom: 130px;
            }
        }
    }
`

export const StyledRiddleTitle = styled.div<{ type: "question" | "answer" }>`
    border: 1px solid ${colors.lineGrey};
    box-sizing: border-box;
    border-radius: 5px;
    font-weight: 500;
    padding: 14px;
    font-size: 16px;
    border-left: 5px solid
        ${({ type }) => {
            switch (type) {
                case "question":
                    return colors.yellow
                case "answer":
                    return colors.green
                default:
                    return colors.lineGrey
            }
        }};
`

export const StyledForm = styled.div`
    margin: 20px auto auto;
    width: 80%;
    max-width: 392px;

    .fieldWrapper {
        position: relative;
    }

    .percent {
        position: absolute;
        right: 46px;
        top: 17px;
    }

    .buttonsBlock {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;

        & > * {
            width: 45%;
        }
    }

    @media (max-width: 575px) {
        max-width: 100%;
        width: 100%;

        .buttonsBlock {
            padding-bottom: 20px;
        }

        @media not all and (min-resolution: 0.001dpcm) {
            .buttonsBlock {
                padding-bottom: 130px;
            }
        }
    }
`

export const StyledShareWithButtons = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .buttonsBlock {
        display: flex;
        margin-top: 30px;
        justify-content: space-between;
        width: 80%;
        min-width: 300px;
        & > * {
            width: 45%;
        }
    }
    .socialNetworks {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        width: 110px;
    }

    @media (max-width: 575px) {
        padding-bottom: 30px;

        @media not all and (min-resolution: 0.001dpcm) {
            .buttonsBlock {
                padding-bottom: 130px;
            }
        }
    }
`
export const StyledPagination = styled.div`
    display: flex;
    justify-content: center;

    & > * {
        margin-right: 10px;
    }
    & > *:last-child {
        margin-right: 0;
    }

    .arrowNext {
        cursor: pointer;
        display: flex;
        transform: rotate(180deg);
    }

    .arrowBack {
        cursor: pointer;
        display: flex;
    }
`

export const StyledPaginationItem = styled.div<{ state: "answer" | "question"; active: boolean }>`
    padding: 4px 10px;
    border-radius: 2px;

    cursor: default;

    background: ${({ active, state }) => {
        if (!active) return "unset"
        switch (state) {
            case "answer":
                return colors.green
            case "question":
                return colors.yellow
            default:
                return "unset"
        }
    }};

    color: ${({ active }) => (active ? colors.white : colors.grey)};

    border: ${({ active }) => (active ? "unset" : `1px solid ${colors.grey}`)};
`

export const StyledConfirmRiddleExitModal = styled.div`
    text-align: center;

    .buttonsBlock {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        & > * {
            width: 45%;
        }
    }
`
