import { useDispatch } from "react-redux"
import { addModal, removeModal, setRiddlesAnswers } from "../../../../redux/actions/ui/ui"
import { RIDDLE_MODAL_ANSWER_ONE, RIDDLE_MODAL_QUESTION_ONE } from "../../../../constants/modals"
import RiddlesModalAnswerOne from "./RiddlesModalAnswerOne"
import AnswerForm from "./AnswerForm"
import { FormValues } from "./Riddles.types"
import TooltipLight from "../../../../reusableComponents/TooltipLight"

//styles
import { StyledRiddle, StyledRiddleTitle } from "./Riddles.styles"
import { StyledH3, StyledBodyMain } from "../../../../styles/Text/Text.styles"
import Pagination from "./Pagination"
import { useRiddleExit } from "../../../../hooks/useRiddleExit"

const RiddlesModalQuestionOne = () => {
    const dispatch = useDispatch()

    const [onExit] = useRiddleExit()

    const onSubmit = ({ answer }: FormValues) => {
        dispatch(setRiddlesAnswers({ 1: answer || 0 }))
        dispatch(removeModal(RIDDLE_MODAL_QUESTION_ONE))
        dispatch(
            addModal({
                id: RIDDLE_MODAL_ANSWER_ONE,
                children: <RiddlesModalAnswerOne />,
                hideLogo: true,
                hideCross: true,
                exitOnClickOutside: true,
                exitAction: onExit,
            })
        )
    }
    return (
        <StyledRiddle>
            <Pagination
                riddleNumber={1}
                state="question"
                currentModalId={RIDDLE_MODAL_QUESTION_ONE}
                nextModalId={RIDDLE_MODAL_ANSWER_ONE}
                nextModalChildren={<RiddlesModalAnswerOne />}
            />
            <div className="marginTop">
                <StyledRiddleTitle type="question">
                    Riddles - How good is your intuition when it comes to probabilities?
                </StyledRiddleTitle>
            </div>
            <div className="marginTop">
                <StyledH3 weight={700}>HIV test</StyledH3>
            </div>
            <div className="marginTop">
                <StyledBodyMain>
                    One person is selected at random to do the HIV test. The test result comes out
                    positive.
                </StyledBodyMain>
            </div>
            <div className="marginTop">
                <StyledBodyMain>
                    The{" "}
                    <TooltipLight
                        size="small"
                        title={
                            <StyledBodyMain color="blue" className="hint">
                                false positive
                            </StyledBodyMain>
                        }
                        text="An error in which the test result comes out positive, when in fact the person does not have the condition tested for."
                    />{" "}
                    rate of HIV tests is 1.5%.
                </StyledBodyMain>
            </div>
            <div>
                <StyledBodyMain>
                    The{" "}
                    <TooltipLight
                        size="small"
                        title={
                            <StyledBodyMain color="blue" className="hint">
                                false negative
                            </StyledBodyMain>
                        }
                        text="An error in which the test result comes out negative, when in fact the person does have the condition tested for."
                    />{" "}
                    rate is 0.3%.
                    <a
                        className="link"
                        href="http://www.wikiwand.com/en/Diagnosis_of_HIV/AIDS#/Accuracy_of_HIV_testing"
                        target="_blank"
                        rel="noreferrer"
                    >
                        [1]
                    </a>
                </StyledBodyMain>
            </div>
            <div className="marginTop smallMarginBottom alignCenter">
                <StyledBodyMain>Based on your intuition:</StyledBodyMain>
            </div>
            <div className="alignCenter">
                <StyledBodyMain weight={700}>
                    What is the likelihood that this person really has HIV?
                </StyledBodyMain>
            </div>

            <AnswerForm onSubmit={onSubmit} />
        </StyledRiddle>
    )
}

export default RiddlesModalQuestionOne
