import styled from "styled-components"
import { colors } from "../../../constants/colors"

export const StyledTextArea = styled.textarea`
    padding: 14px 20px;
    width: 100%;
    resize: vertical;
    border: 1px solid ${colors.lightGrey};
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;

    &:focus {
        border: 1px solid ${colors.grey};
        outline: none;
    }

    @media (max-width: 575px) {
        font-size: 16px;
    }
`
