import { useDispatch } from "react-redux"
import AnswerForm from "./AnswerForm"
import { addModal, removeModal, setRiddlesAnswers } from "../../../../redux/actions/ui/ui"
import {
    RIDDLE_MODAL_ANSWER_FOUR,
    RIDDLE_MODAL_ANSWER_THREE,
    RIDDLE_MODAL_QUESTION_FOUR,
} from "../../../../constants/modals"
import { FormValues } from "./Riddles.types"
import RiddlesModalAnswerFour from "./RiddlesModalAnswerFour"

//styles
import { StyledRiddle, StyledRiddleTitle } from "./Riddles.styles"
import { StyledBodyMain, StyledH3 } from "../../../../styles/Text/Text.styles"
import Pagination from "./Pagination"
import RiddlesModalAnswerThree from "./RiddlesModalAnswerThree"
import { useRiddleExit } from "../../../../hooks/useRiddleExit"

const RiddlesModalQuestionFour = () => {
    const dispatch = useDispatch()

    const [onExit] = useRiddleExit()

    const onSubmit = ({ answer }: FormValues) => {
        dispatch(setRiddlesAnswers({ 4: answer || 0 }))
        dispatch(removeModal(RIDDLE_MODAL_QUESTION_FOUR))
        dispatch(
            addModal({
                id: RIDDLE_MODAL_ANSWER_FOUR,
                children: <RiddlesModalAnswerFour />,
                hideLogo: true,
                hideCross: true,
                exitOnClickOutside: true,
                exitAction: onExit,
            })
        )
    }

    return (
        <StyledRiddle>
            <Pagination
                riddleNumber={4}
                state="question"
                currentModalId={RIDDLE_MODAL_QUESTION_FOUR}
                prevModalId={RIDDLE_MODAL_ANSWER_THREE}
                prevModalChildren={<RiddlesModalAnswerThree />}
                nextModalId={RIDDLE_MODAL_ANSWER_FOUR}
                nextModalChildren={<RiddlesModalAnswerFour />}
            />
            <div className="marginTop">
                <StyledRiddleTitle type="question">
                    Riddles - How good is your intuition when it comes to probabilities?
                </StyledRiddleTitle>
            </div>
            <div className="marginTop">
                <StyledH3 weight={700}>Under the weather</StyledH3>
            </div>

            <div className="marginTop">
                <StyledBodyMain>
                    Assume a child has a 10% chance of being sick at some point in any given month.
                    The Brady family has 6 children.
                </StyledBodyMain>
            </div>

            <div className="marginTop smallMarginBottom alignCenter">
                <StyledBodyMain>Based on your intuition:</StyledBodyMain>
            </div>
            <div className="alignCenter">
                <StyledBodyMain weight={700}>
                    What is the probability that all 6 children in the Brady family will be sick
                    next month?
                </StyledBodyMain>
            </div>

            <AnswerForm onSubmit={onSubmit} />
        </StyledRiddle>
    )
}

export default RiddlesModalQuestionFour
