import styled from "styled-components"
import { colors } from "../../constants/colors"

type Props = {
    width?: "large" | "medium"
    position?: "center" | "" | "left"
    revert?: boolean
    isMobile: boolean
}

const handleWidth = (width: string | undefined) => {
    switch (width) {
        case "large":
            return "450px"
        case "medium":
            return "320px"
        default:
            return "400px"
    }
}

export const StyledTooltip = styled.span<Props>`
    align-items: center;
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    height: 0;
    margin: 0;
    position: relative;

    &:hover .textWrapper,
    .arrow:hover .textWrapper {
        animation: show 300ms linear forwards;
        display: flex;
    }

    * {
        box-sizing: border-box;
    }

    .icon {
        cursor: pointer;
        display: flex;
        margin: 0 12px;
        min-width: 24px;
    }

    .arrow {
        border-color: transparent transparent ${colors.colorIcon} transparent;
        border-style: solid;
        border-width: 0 7px 7px 7px;
        position: absolute;

        padding-top: 20px;
        margin: 0 20px;
    }

    .textWrapper {
        height: 0;
        width: 0;
        display: none;
        flex-direction: column;
        position: absolute;
        z-index: 10;
        left: ${({ revert }) => (revert ? "initial" : "-4px")};
        right: ${({ revert }) => (revert ? "-4px" : "initial")};
        top: initial;
        bottom: -4px;
    }

    .text {
        background-color: ${colors.colorIcon};
        line-height: 20px;
        padding: 10px 15px;
        width: ${({ width }) => handleWidth(width)};
        margin-top: 26px;
        text-align: left;

        transform: translate(
            ${({ position, revert }) => {
                if (position === "left" && revert) {
                    return 0
                }

                if (position === "left") {
                    return "calc(-100% + 72px)"
                }

                if (position === "center" && revert) {
                    return "50%"
                }

                if (position === "center") {
                    return "-50%"
                }

                if (revert) {
                    return "calc(100% - 72px)"
                }
                return 0
            }},
            0
        );
    }

    .btnClose {
        appearance: none;
        background-color: transparent;
        border: none;
        display: none;

        img {
            height: 16px;
            width: 16px;
        }
    }

    @keyframes show {
        0% {
            opacity: 0;
            visibility: hidden;
        }
        100% {
            opacity: 1;
            visibility: visible;
        }
    }

    @media (max-width: 575px) {
        .arrow {
            display: none;
        }

        .tooltipText {
            box-shadow: 0 0 50px rgb(0 0 0 / 35%);
        }

        .textWrapper {
            height: initial;
            display: none;
            flex-direction: unset;
            position: fixed;
            width: unset;
            transform: unset;
            padding-top: 0;
            top: unset;
            z-index: 10;
            align-items: unset;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .text {
            padding: 20px;
            width: 100%;
            transform: translate(0, 0);
        }

        .btnClose {
            position: absolute;
            display: block;
            top: 30px;
            right: 10px;
        }
    }
`
