import { Field, Form, Formik, FormikProps } from "formik"
import ButtonOutlined from "../../ButtonOutlined"
import ButtonPrimary from "../../ButtonPrimary"
import Input from "../../Formik/Input"
import TextArea from "../../Formik/Textarea"
import CustomErrorMessage from "../../CustomErrorMessage"
import { useDispatch, useSelector } from "react-redux"
import { addModal } from "../../../redux/actions/ui/ui"
import { CONFIRM_SHARE_EXIT } from "../../../constants/modals"
import { AppState } from "../../../redux/reducers"
import { shareByEmailActions } from "../../../redux/actions/user/user"
import { EMAIL_BODY } from "../../../constants"
import { ShareEmailSchema } from "./ShareEmail.schema"
import { StyledModal } from "../ShareIconsBlock.styles"
import ConfirmShareExitModal from "../ConfirmShareExitModal"
import { FC, useRef } from "react"

type FormValues = {
    from: string
    to: string
    subject: string
    message: string
    body: FC | null
}

type Props = {
    afterSend?: () => void
    body: FC
    subject: string
}

const ShareEmailModal: React.FC<Props> = ({ afterSend, body: Body, subject }) => {
    const dispatch = useDispatch()
    const { email, isGuest } = useSelector((app: AppState) => app.user)

    const fromRef = useRef<HTMLInputElement>(null)
    const toRef = useRef<HTMLInputElement>(null)
    const subjectRef = useRef<HTMLInputElement>(null)
    const formRef = useRef<FormikProps<FormValues>>(null)

    const onSubmit = ({ message, ...values }: FormValues) => {
        const body = document.getElementById(EMAIL_BODY)?.innerHTML

        const customMessage = `<p style="serif; font-size: 16px; color: #000; max-width: 740px; margin: 0 auto;">${message}</p>`

        dispatch(
            shareByEmailActions.request({ ...values, message: customMessage, body, afterSend })
        )
    }

    const onCancel = () => {
        dispatch(
            addModal({
                id: CONFIRM_SHARE_EXIT,
                children: <ConfirmShareExitModal afterSend={afterSend} />,
                hideCross: true,
                hideLogo: true,
                size: "small",
            })
        )
    }

    const beforeSubmit = async () => {
        const errors = await formRef?.current?.validateForm()

        switch (Object.keys(errors || {})[0]) {
            case "from": {
                fromRef?.current?.scrollIntoView({ behavior: "smooth" })
                return
            }
            case "to": {
                toRef?.current?.scrollIntoView({ behavior: "smooth" })
                return
            }
            case "subject": {
                subjectRef?.current?.scrollIntoView({
                    behavior: "smooth",
                })
                return
            }
            default: {
                return
            }
        }
    }

    return (
        <StyledModal>
            <Formik
                initialValues={{
                    from: isGuest ? "" : email,
                    to: "",
                    subject,
                    message: "",
                    body: null,
                }}
                onSubmit={onSubmit}
                validationSchema={ShareEmailSchema}
                innerRef={formRef}
            >
                {() => (
                    <Form>
                        <div className="formRow">
                            <Field
                                name="from"
                                as={Input}
                                placeholder="From (enter email)"
                                innerRef={fromRef}
                            />
                            <CustomErrorMessage name="from" component="div" />
                        </div>
                        <div className="formRow">
                            <Field
                                name="to"
                                as={Input}
                                placeholder="To (enter email)"
                                innerRef={toRef}
                            />
                            <CustomErrorMessage name="to" component="div" />
                        </div>
                        <div className="formRow">
                            <Field
                                name="subject"
                                as={Input}
                                placeholder="Subject"
                                innerRef={subjectRef}
                            />
                            <CustomErrorMessage name="subject" component="div" />
                        </div>

                        <Body />

                        <Field
                            type="textarea"
                            name="message"
                            as={TextArea}
                            placeholder="Input message here..."
                            block
                        />
                        <div className="formAction">
                            <ButtonOutlined type="button" onClick={onCancel}>
                                Cancel
                            </ButtonOutlined>
                            <ButtonPrimary type="submit" onClick={beforeSubmit}>
                                Submit
                            </ButtonPrimary>
                        </div>
                    </Form>
                )}
            </Formik>
        </StyledModal>
    )
}

export default ShareEmailModal
