import React, { useState } from "react"
import { StyledTooltip } from "./TooltipDark.styles"
import { StyledSubText } from "../../styles/Text/Text.styles"
import { isMobile } from "../../helpers"

type Props = {
    children: React.ReactNode
    icon?: React.ReactNode
    width?: "large" | "medium"
    position?: "center" | "" | "left"
    revert?: boolean
}

const TooltipDark = ({ children, icon, width, position, revert }: Props) => {
    const [show, setShow] = useState(false)

    return (
        <StyledTooltip width={width} position={position} revert={revert} isMobile={isMobile()}>
            <span className="icon" onMouseEnter={() => setShow(true)} onClick={() => setShow(true)}>
                {icon || <img src="/icons/info.svg" alt="info icon" />}
            </span>
            {show && (
                <span className="textWrapper tooltipTextWrapper">
                    <span className="arrow" />
                    <span className="text tooltipText d-block">
                        <StyledSubText color="white" rtl={revert}>
                            {children}
                        </StyledSubText>
                    </span>
                    <button type="button" className="btnClose" onClick={() => setShow(false)}>
                        <img alt="icon" src="/icons/cross.svg" width={16} height={16} />
                    </button>
                </span>
            )}
        </StyledTooltip>
    )
}

export default TooltipDark
