import MathJax from "react-mathjax"
import ButtonOutlined from "src/reusableComponents/ButtonOutlined"
import Pagination from "./Pagination"
import { useAnswers } from "./Riddles.helpers"
import { RIDDLE_MODAL_ANSWER_FOUR, RIDDLE_MODAL_QUESTION_FOUR } from "src/constants/modals"
import RiddlesModalQuestionFour from "./RiddlesModalQuestionFour"
import { useDispatch } from "react-redux"
import { removeAllModal, resetRiddlesAnswers } from "src/redux/actions/ui/ui"
import ShareWithButtons from "./ShareWithButtons"

// components

//styles
import { StyledRiddle, StyledRiddleTitle } from "./Riddles.styles"
import { StyledBodyMain, StyledH3 } from "src/styles/Text/Text.styles"

const RiddlesModalAnswerFour = () => {
    const answers = useAnswers()
    const dispatch = useDispatch()

    const onCancel = () => {
        dispatch(resetRiddlesAnswers())
        dispatch(removeAllModal())
    }

    return (
        <StyledRiddle>
            <Pagination
                riddleNumber={4}
                state="answer"
                currentModalId={RIDDLE_MODAL_ANSWER_FOUR}
                prevModalId={RIDDLE_MODAL_QUESTION_FOUR}
                prevModalChildren={<RiddlesModalQuestionFour />}
            />
            <MathJax.Provider options={{ messageStyle: "none", tex2jax: { preview: "none" } }}>
                <div className="marginTop">
                    <StyledRiddleTitle type="answer">
                        Riddles - How good is your intuition when it comes to probabilities?
                    </StyledRiddleTitle>
                </div>
                <div className="marginTop">
                    <StyledH3 weight={700}>Under the weather</StyledH3>
                </div>

                <div className="resultBox marginTop">
                    <div>Your estimate: {answers[4]}%</div>
                    <div className="resultString">
                        Actual answer: <StyledBodyMain weight={700}>0.072%</StyledBodyMain>
                    </div>

                    <div>
                        that all 6 Brady children will be sick next month.
                        <span className="redStar">*</span>
                    </div>
                </div>

                <div className="marginTop marginBottom">
                    <StyledBodyMain weight={700}>How do we get to this answer?</StyledBodyMain>
                </div>

                <div className="marginBottom">
                    <StyledBodyMain weight={700} color="grey">
                        A wrong (but tempting) answer would be:
                    </StyledBodyMain>
                </div>

                <div className="marginTop">
                    <MathJax.Node formula={"10\\%^{6} = 0.0001\\%"} />
                </div>
                <div className="marginTop marginBottom">
                    The right (but less obvious) answer takes into account the fact that children
                    who spend a lot of time near other sick children (children in the same family,
                    for example) are more likely themselves to get sick. In other words, the events
                    are dependent.
                </div>

                <div className="marginBottom">
                    <StyledBodyMain>
                        So if A, B, C, D, E, and F represent each child in the Brady family getting
                        sick:
                    </StyledBodyMain>
                </div>

                <MathJax.Node formula={"P(A,B,C,D,E,F)"} />
                <MathJax.Node formula={"="} />
                <div className="overflowAuto">
                    <MathJax.Node
                        formula={
                            "P(A|B,C,D,E,F) * P(B|C,D,E,F) * P(C|D,E,F) * P(D|E,F) * P(E|F) *  P(F)"
                        }
                    />
                </div>
                <MathJax.Node formula={"="} />
                <MathJax.Node formula={"60\\% * 50\\% * 40\\% * 30\\% * 20\\% * 10\\%"} />
                <MathJax.Node formula={"="} />
                <MathJax.Node formula={"0.072\\%"} />
                <div className="marginTop">
                    Assuming these numbers, the right answer would come out 720 times greater than
                    the wrong (but more intuitive) answer!
                </div>
                <div className="marginTop">
                    <StyledBodyMain color="grey">
                        <span className="redStar">*</span>These numbers are for illustrative
                        purposes only, but the principle holds even if the exact numbers change: if
                        some children in the family get sick, there’s a higher chance that other
                        children in the same family will also get sick.
                    </StyledBodyMain>
                </div>

                <ShareWithButtons hideButtonsBlock />

                <div className="marginTop lastCancelButton">
                    <ButtonOutlined onClick={onCancel} block>
                        Close
                    </ButtonOutlined>
                </div>
            </MathJax.Provider>
        </StyledRiddle>
    )
}

export default RiddlesModalAnswerFour
