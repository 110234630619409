import { StyledH3 } from "../../../../styles/Text/Text.styles"
import { StyledConfirmRiddleExitModal } from "./Riddles.styles"
import ButtonOutlined from "../../../../reusableComponents/ButtonOutlined"
import ButtonPrimary from "../../../../reusableComponents/ButtonPrimary"
import { useDispatch } from "react-redux"
import { removeAllModal, removeModal, resetRiddlesAnswers } from "../../../../redux/actions/ui/ui"
import { CONFIRM_RIDDLE_EXIT } from "../../../../constants/modals"

const ConfirmRiddleExitModal = () => {
    const dispatch = useDispatch()
    const onCancel = () => {
        dispatch(removeModal(CONFIRM_RIDDLE_EXIT))
    }
    const onSubmit = () => {
        dispatch(resetRiddlesAnswers())
        dispatch(removeAllModal())
    }
    return (
        <StyledConfirmRiddleExitModal>
            <StyledH3 weight={700}>Exit Riddles?</StyledH3>
            <div className="buttonsBlock">
                <ButtonOutlined block onClick={onCancel}>
                    Keep Riddling
                </ButtonOutlined>
                <ButtonPrimary block onClick={onSubmit}>
                    Exit
                </ButtonPrimary>
            </div>
        </StyledConfirmRiddleExitModal>
    )
}

export default ConfirmRiddleExitModal
