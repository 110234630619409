import { StyledH3 } from "../../../styles/Text/Text.styles"
import { StyledConfirmShareExitModal } from "./ConfirmShareExitModal.styles"
import ButtonOutlined from "../../../reusableComponents/ButtonOutlined"
import ButtonPrimary from "../../../reusableComponents/ButtonPrimary"
import { useDispatch } from "react-redux"
import { removeAllModal, removeModal } from "../../../redux/actions/ui/ui"
import { CONFIRM_SHARE_EXIT } from "../../../constants/modals"
import { FC } from "react"

type Props = {
    afterSend?: () => void
}

const ConfirmShareExitModal: FC<Props> = ({ afterSend }) => {
    const dispatch = useDispatch()
    const onCancel = () => {
        dispatch(removeModal(CONFIRM_SHARE_EXIT))
    }
    const onSubmit = () => {
        dispatch(removeAllModal())
        afterSend && afterSend()
    }
    return (
        <StyledConfirmShareExitModal>
            <StyledH3 weight={700}>Discard unfinished email?</StyledH3>
            <div className="buttonsBlock">
                <ButtonOutlined block onClick={onCancel}>
                    Continue Editing
                </ButtonOutlined>
                <ButtonPrimary block onClick={onSubmit}>
                    Discard
                </ButtonPrimary>
            </div>
        </StyledConfirmShareExitModal>
    )
}

export default ConfirmShareExitModal
