import { useDispatch } from "react-redux"
import AnswerForm from "./AnswerForm"
import { addModal, removeModal, setRiddlesAnswers } from "../../../../redux/actions/ui/ui"
import {
    RIDDLE_MODAL_ANSWER_THREE,
    RIDDLE_MODAL_ANSWER_TWO,
    RIDDLE_MODAL_QUESTION_THREE,
} from "../../../../constants/modals"
import { FormValues } from "./Riddles.types"
import RiddlesModalAnswerThree from "./RiddlesModalAnswerThree"

//styles
import { StyledRiddle, StyledRiddleTitle } from "./Riddles.styles"
import { StyledBodyMain, StyledH3 } from "../../../../styles/Text/Text.styles"
import Pagination from "./Pagination"
import RiddlesModalAnswerTwo from "./RiddlesModalAnswerTwo"
import { useRiddleExit } from "../../../../hooks/useRiddleExit"

const RiddlesModalQuestionThree = () => {
    const dispatch = useDispatch()

    const [onExit] = useRiddleExit()

    const onSubmit = ({ answer }: FormValues) => {
        dispatch(setRiddlesAnswers({ 3: answer || 0 }))
        dispatch(removeModal(RIDDLE_MODAL_QUESTION_THREE))
        dispatch(
            addModal({
                id: RIDDLE_MODAL_ANSWER_THREE,
                children: <RiddlesModalAnswerThree />,
                hideLogo: true,
                hideCross: true,
                exitOnClickOutside: true,
                exitAction: onExit,
            })
        )
    }

    return (
        <StyledRiddle>
            <Pagination
                riddleNumber={3}
                state="question"
                currentModalId={RIDDLE_MODAL_QUESTION_THREE}
                prevModalId={RIDDLE_MODAL_ANSWER_TWO}
                prevModalChildren={<RiddlesModalAnswerTwo />}
                nextModalId={RIDDLE_MODAL_ANSWER_THREE}
                nextModalChildren={<RiddlesModalAnswerThree />}
            />
            <div className="marginTop">
                <StyledRiddleTitle type="question">
                    Riddles - How good is your intuition when it comes to probabilities?
                </StyledRiddleTitle>
            </div>
            <div className="marginTop">
                <StyledH3 weight={700}>Boxes and Balls II</StyledH3>
            </div>

            <div className="marginTop">
                <StyledBodyMain>
                    As in the previous riddle, Box A has ⅔ white balls and ⅓ black balls, Box B has
                    ⅔ black balls and ⅓ white balls.
                </StyledBodyMain>
            </div>
            <div className="marginTop">
                <StyledBodyMain>
                    But this time somebody else chose one of the boxes at random, and is pulling out
                    the balls behind a curtain where you can’t see him. Each time he pulls out a
                    ball, he tells you a color, then returns the ball to the box. The catch is, he
                    doesn’t always tell the truth.
                </StyledBodyMain>
            </div>
            <div className="marginTop">
                <StyledBodyMain>
                    When he pulls out a white ball, he tells the truth 100% of the time. But when he
                    pulls out a black ball, he tells the truth only 80% of the time (the other 20%
                    of the time he lies and says he pulled out a white ball).
                </StyledBodyMain>
            </div>
            <div className="marginTop">
                <StyledBodyMain>
                    Out of the 50 times he drew a ball, he reported white 30 times.
                </StyledBodyMain>
            </div>

            <div className="marginTop smallMarginBottom alignCenter">
                <StyledBodyMain>Based on your intuition:</StyledBodyMain>
            </div>
            <div className="alignCenter">
                <StyledBodyMain weight={700}>
                    What is the likelihood that the balls were drawn from Box A?
                </StyledBodyMain>
            </div>

            <AnswerForm onSubmit={onSubmit} />
        </StyledRiddle>
    )
}
export default RiddlesModalQuestionThree
