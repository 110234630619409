import { EMAIL_BODY } from "../../../constants"
import { FC } from "react"
import ShareUrl from "./ShareUrl"
import { isBrowser } from "../../../helpers"

const ShareRiddle: FC = () => {
    const origin = isBrowser() ? window.location.origin : ""

    return (
        <div style={{ margin: "0 auto", maxWidth: "740px" }} id={EMAIL_BODY}>
            <div style={{ margin: "0 auto", maxWidth: "740px" }}>
                <table role="presentation" style={{ width: "100%", border: 0 }}>
                    <tbody>
                        <tr>
                            <td
                                style={{
                                    direction: "ltr",
                                    fontSize: 0,
                                    padding: "20px 0 0",
                                    textAlign: "center",
                                }}
                            >
                                <div
                                    style={{
                                        fontSize: 0,
                                        textAlign: "left",
                                        direction: "ltr",
                                        display: "inline-block",
                                        verticalAlign: "top",
                                        width: "100%",
                                    }}
                                >
                                    <table
                                        role="presentation"
                                        style={{ verticalAlign: "top", border: 0 }}
                                        width="100%"
                                    >
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "128px" }}>
                                                    <img
                                                        height="auto"
                                                        src={`${origin}/images/logo.png`}
                                                        style={{
                                                            border: 0,
                                                            display: "block",
                                                            outline: "none",
                                                            textDecoration: "none",
                                                            height: "auto",
                                                            width: "100%",
                                                            fontSize: "13px",
                                                        }}
                                                        width="128"
                                                        alt="logo"
                                                    />
                                                </td>
                                                <td
                                                    align="right"
                                                    style={{
                                                        fontSize: 0,
                                                        padding: "25px 25px 10px",
                                                        wordBreak: "break-word",
                                                    }}
                                                >
                                                    <a
                                                        href="rootclaim.com"
                                                        style={{
                                                            fontSize: "14px",
                                                            lineHeight: 1.3,
                                                            textAlign: "right",
                                                            color: "#000",
                                                            textDecoration: "none",
                                                        }}
                                                    >
                                                        ROOTCLAIM.COM
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table role="presentation" style={{ width: "100%", border: 0 }}>
                    <tbody>
                        <tr>
                            <td
                                style={{
                                    direction: "ltr",
                                    fontSize: 0,
                                    padding: "20px 0 0",
                                    textAlign: "center",
                                }}
                            >
                                <div
                                    style={{
                                        fontSize: 0,
                                        textAlign: "left",
                                        direction: "ltr",
                                        display: "inline-block",
                                        verticalAlign: "top",
                                        width: "100%",
                                    }}
                                >
                                    <table
                                        role="presentation"
                                        style={{
                                            verticalAlign: "top",
                                            border: 0,
                                        }}
                                        width="100%"
                                    >
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "100%" }}>
                                                    <img
                                                        src={origin + "/images/background.jpg"}
                                                        width="730"
                                                        height="300"
                                                        alt=""
                                                        style={{
                                                            objectFit: "cover",
                                                            maxWidth: "100%",
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table role="presentation" style={{ width: "100%" }}>
                    <tbody>
                        <tr>
                            <td
                                style={{
                                    direction: "ltr",
                                    fontSize: 0,
                                    padding: "0 0 20px",
                                    textAlign: "center",
                                }}
                            >
                                <div
                                    style={{
                                        fontSize: 0,
                                        textAlign: "left",
                                        direction: "ltr",
                                        display: "inline-block",
                                        verticalAlign: "top",
                                        width: "100%",
                                    }}
                                >
                                    <table
                                        role="presentation"
                                        style={{ verticalAlign: "top", border: 0 }}
                                        width="100%"
                                    >
                                        <tbody>
                                            <tr>
                                                <td
                                                    align="left"
                                                    style={{
                                                        fontSize: 0,
                                                        padding: "10px 0px 0px",
                                                        wordBreak: "break-word",
                                                    }}
                                                >
                                                    <div>
                                                        <span
                                                            style={{
                                                                fontSize: "20px",
                                                                lineHeight: 1.3,
                                                                textAlign: "left",
                                                                color: "#000000",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            How's your intuition when it comes to
                                                            probability? Test yourself with these 4
                                                            riddles. | Rootclaim
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    align="left"
                                                    style={{
                                                        fontSize: 0,
                                                        padding: "10px 0px",
                                                        wordBreak: "break-word",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            fontSize: "16px",
                                                            lineHeight: 1.3,
                                                            textAlign: "left",
                                                            color: "#000",
                                                        }}
                                                    >
                                                        Rootclaim's mathematical engine does what
                                                        humans can't: it balances all the inputs and
                                                        computes the probabilities. See how your
                                                        intuition measures up.
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    align="left"
                                                    style={{
                                                        fontSize: 0,
                                                        padding: "0",
                                                        wordBreak: "break-word",
                                                    }}
                                                >
                                                    <div>
                                                        <ShareUrl
                                                            href={origin + "/riddles"}
                                                            name={"Test yourself here."}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ShareRiddle
