import { Field, Form, Formik } from "formik"
import { riddlesModalSchema } from "./RiddlesModalSchema"
import { FormProps, FormValues } from "./Riddles.types"
import Input from "../../../../reusableComponents/Formik/Input"
import CustomErrorMessage from "../../../../reusableComponents/CustomErrorMessage"
import { StyledForm } from "./Riddles.styles"
import ButtonOutlined from "../../../../reusableComponents/ButtonOutlined"
import ButtonPrimary from "../../../../reusableComponents/ButtonPrimary"
import { StyledSubText } from "../../../../styles/Text/Text.styles"
import { useRiddleExit } from "../../../../hooks/useRiddleExit"

const initialValues: FormValues = { answer: undefined }

const AnswerForm = ({ onSubmit }: FormProps) => {
    const [onExit] = useRiddleExit()

    return (
        <StyledForm>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={riddlesModalSchema}
            >
                {({ submitForm, isValid, dirty }) => (
                    <Form>
                        <div className="fieldWrapper">
                            <Field
                                type="number"
                                name="answer"
                                min={0}
                                max={100}
                                as={Input}
                                placeholder="Answer"
                                block
                            />
                            <StyledSubText className="percent" inline>
                                %
                            </StyledSubText>
                        </div>
                        <CustomErrorMessage name="answer" component="div" />
                        <div className="buttonsBlock">
                            <ButtonOutlined block onClick={onExit}>
                                Cancel
                            </ButtonOutlined>
                            <ButtonPrimary
                                onClick={submitForm}
                                disabled={!(isValid && dirty)}
                                block
                            >
                                Submit & Check
                            </ButtonPrimary>
                        </div>
                    </Form>
                )}
            </Formik>
        </StyledForm>
    )
}

export default AnswerForm
