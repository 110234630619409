import { useEffect } from "react"
import Image from "next/image"
import { FacebookShareButton, TwitterShareButton } from "react-share"
import { useDispatch } from "react-redux"
import { addModal, removeAllModal } from "../../redux/actions/ui/ui"
import { SHARE_EMAIL_MODAL } from "../../constants/modals"
import ShareEmailModal from "./ShareEmailModal"
import { StyledButton } from "./ShareIconsBlock.styles"
import { FC } from "react"

type Props = {
    url: string
    body: FC
    afterSend?: () => void
    subject: string
    twitterTitle?: string
}

const ShareIconsBlock = ({ url, body, afterSend, subject, twitterTitle }: Props) => {
    const dispatch = useDispatch()
    let modalBox: HTMLElement | null

    useEffect(() => {
        modalBox = document.getElementById("modalBox")
    }, [])

    const onEmailSend = () => {
        dispatch(removeAllModal())
        modalBox ? (modalBox.scrollTop = 0) : null
        dispatch(
            addModal({
                id: SHARE_EMAIL_MODAL,
                children: <ShareEmailModal body={body} afterSend={afterSend} subject={subject} />,
                hideLogo: true,
                hideCross: true,
            })
        )
    }

    return (
        <>
            <StyledButton>
                <FacebookShareButton url={url}>
                    <Image
                        src="/icons/social/facebookBlackOutlined.svg"
                        alt="facebook"
                        width={30}
                        height={30}
                    />
                </FacebookShareButton>
            </StyledButton>
            <StyledButton>
                <TwitterShareButton url={url} title={twitterTitle}>
                    <Image
                        src="/icons/social/twitterBlackOutlined.svg"
                        alt="twitter"
                        width={30}
                        height={30}
                    />
                </TwitterShareButton>
            </StyledButton>
            <StyledButton>
                <Image
                    src="/icons/social/emailBlackOutlined.svg"
                    alt="email"
                    width={30}
                    height={30}
                    onClick={onEmailSend}
                />
            </StyledButton>
        </>
    )
}

export default ShareIconsBlock
