import { useDispatch } from "react-redux"
import AnswerForm from "./AnswerForm"
import { addModal, removeModal, setRiddlesAnswers } from "../../../../redux/actions/ui/ui"
import {
    RIDDLE_MODAL_ANSWER_ONE,
    RIDDLE_MODAL_ANSWER_TWO,
    RIDDLE_MODAL_QUESTION_TWO,
} from "../../../../constants/modals"
import RiddlesModalAnswerTwo from "./RiddlesModalAnswerTwo"
import { FormValues } from "./Riddles.types"

//styles
import { StyledRiddle, StyledRiddleTitle } from "./Riddles.styles"
import { StyledBodyMain, StyledH3 } from "../../../../styles/Text/Text.styles"
import Pagination from "./Pagination"
import RiddlesModalAnswerOne from "./RiddlesModalAnswerOne"
import { useRiddleExit } from "../../../../hooks/useRiddleExit"

const RiddlesModalQuestionTwo = () => {
    const dispatch = useDispatch()

    const [onExit] = useRiddleExit()

    const onSubmit = ({ answer }: FormValues) => {
        dispatch(setRiddlesAnswers({ 2: answer || 0 }))
        dispatch(removeModal(RIDDLE_MODAL_QUESTION_TWO))
        dispatch(
            addModal({
                id: RIDDLE_MODAL_ANSWER_TWO,
                children: <RiddlesModalAnswerTwo />,
                hideLogo: true,
                hideCross: true,
                exitOnClickOutside: true,
                exitAction: onExit,
            })
        )
    }
    return (
        <StyledRiddle>
            <Pagination
                riddleNumber={2}
                state="question"
                prevModalId={RIDDLE_MODAL_ANSWER_ONE}
                prevModalChildren={<RiddlesModalAnswerOne />}
                currentModalId={RIDDLE_MODAL_QUESTION_TWO}
                nextModalId={RIDDLE_MODAL_ANSWER_TWO}
                nextModalChildren={<RiddlesModalAnswerTwo />}
            />
            <div className="marginTop">
                <StyledRiddleTitle type="question">
                    Riddles - How good is your intuition when it comes to probabilities?
                </StyledRiddleTitle>
            </div>
            <div className="marginTop marginBottom">
                <StyledH3 weight={700}>Boxes and Balls I</StyledH3>
            </div>
            <div className="marginBottom">
                <StyledBodyMain>
                    Box A is filled with balls: ⅔ of the balls are white and ⅓ are black. Box B is
                    also filled with balls, but with the opposite distribution: ⅔ black and ⅓ white.
                </StyledBodyMain>
            </div>
            <div className="marginBottom">
                <StyledBodyMain>
                    You pick one of the boxes at random, but you don’t know whether it’s Box A (with
                    mostly white balls) or Box B (with mostly black balls).
                </StyledBodyMain>
            </div>
            <div className="marginBottom">
                <StyledBodyMain>
                    You draw a ball 50 times out of the box (returning the ball each time), each
                    time recording the color of the ball you pulled out.
                </StyledBodyMain>
            </div>
            <StyledBodyMain>
                Out of the 50 times you drew a ball, you got a white ball 30 times.
            </StyledBodyMain>

            <div className="marginTop smallMarginBottom alignCenter">
                <StyledBodyMain>Based on your intuition:</StyledBodyMain>
            </div>
            <div className="alignCenter">
                <StyledBodyMain weight={700}>
                    What is the likelihood that the balls were drawn from Box A?
                </StyledBodyMain>
            </div>
            <AnswerForm onSubmit={onSubmit} />
        </StyledRiddle>
    )
}

export default RiddlesModalQuestionTwo
