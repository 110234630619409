import React, { useRef, useState, CSSProperties, useEffect } from "react"
import { StyledTextArea } from "./TextArea.styles"

const TextArea = (props: any) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null)
    const [text, setText] = useState("")
    const [textAreaHeight, setTextAreaHeight] = useState("56px")

    useEffect(() => {
        setTextAreaHeight(`${textAreaRef.current!.scrollHeight}px`)
    }, [text])

    const textAreaStyle: CSSProperties = {
        height: textAreaHeight,
    }

    const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (!text) {
            setTextAreaHeight("56px")
        } else {
            setTextAreaHeight("auto")
        }
        setText(event.target.value)

        if (props.onChange) {
            props.onChange(event)
        }
    }

    return (
        <StyledTextArea
            {...props}
            ref={textAreaRef}
            style={{ ...props.styles, ...textAreaStyle }}
            onChange={onChangeHandler}
            rows={1}
        />
    )
}

export default TextArea
