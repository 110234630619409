type Props = {
    href: string
    name: string
}

const ShareUrl = ({ href, name }: Props) => (
    <a
        href={href}
        style={{
            color: "#03A9F4",
            textDecoration: "none",
            fontSize: "16px",
        }}
    >
        {name}
    </a>
)

export default ShareUrl
