import { StyledShareWithButtons } from "./Riddles.styles"
import { StyledH3 } from "../../../../styles/Text/Text.styles"
import ButtonOutlined from "../../../../reusableComponents/ButtonOutlined"
import ButtonPrimary from "../../../../reusableComponents/ButtonPrimary"
import { addModal } from "../../../../redux/actions/ui/ui"
import { CONFIRM_RIDDLE_EXIT } from "../../../../constants/modals"
import ConfirmRiddleExitModal from "./ConfirmRiddleExitModal"
import { useDispatch, useSelector } from "react-redux"
import ShareIconsBlock from "../../../../reusableComponents/ShareIconsBlock"
import { AppState } from "../../../../redux/reducers"
import ShareRiddle from "../../../../reusableComponents/ShareIconsBlock/ShareEmailModal/ShareRiddle"

type Props = {
    onSubmit?: () => void
    hideButtonsBlock?: boolean
    twitterTitle?: string
}

const ShareWithButtons = ({ onSubmit, hideButtonsBlock, twitterTitle }: Props) => {
    const dispatch = useDispatch()

    const [riddleModal] = useSelector((state: AppState) => state.modal.modal)

    const onCancel = () => {
        dispatch(
            addModal({
                id: CONFIRM_RIDDLE_EXIT,
                children: <ConfirmRiddleExitModal />,
                hideCross: true,
                hideLogo: true,
                size: "small",
            })
        )
    }

    return (
        <StyledShareWithButtons>
            <StyledH3 weight={700}>How will your friends do?</StyledH3>

            <div className="socialNetworks">
                <ShareIconsBlock
                    url={`${window.location.origin}/riddles`}
                    body={ShareRiddle}
                    afterSend={() => dispatch(addModal(riddleModal))}
                    subject={"Test your intuition when it comes to probabilities - From Rootclaim"}
                    twitterTitle={twitterTitle}
                />
            </div>

            {!hideButtonsBlock && (
                <div className="buttonsBlock">
                    <ButtonOutlined onClick={onCancel} block>
                        Cancel
                    </ButtonOutlined>
                    <ButtonPrimary onClick={onSubmit} block>
                        Next Riddle
                    </ButtonPrimary>
                </div>
            )}
        </StyledShareWithButtons>
    )
}

export default ShareWithButtons
