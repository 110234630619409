import { addModal, removeModal } from "src/redux/actions/ui/ui"
import {
    RIDDLE_MODAL_ANSWER_THREE,
    RIDDLE_MODAL_QUESTION_FOUR,
    RIDDLE_MODAL_QUESTION_THREE,
} from "src/constants/modals"
import { useDispatch } from "react-redux"

import MathJax from "react-mathjax"

import { useAnswers } from "./Riddles.helpers"

import { useRiddleExit } from "src/hooks/useRiddleExit"

// components
import RiddlesModalQuestionThree from "./RiddlesModalQuestionThree"
import ShareWithButtons from "./ShareWithButtons"
import Pagination from "./Pagination"
import RiddlesModalQuestionFour from "./RiddlesModalQuestionFour"

//styles
import { StyledRiddle, StyledRiddleTitle } from "./Riddles.styles"
import { StyledBodyMain, StyledH3 } from "src/styles/Text/Text.styles"

const RiddlesModalAnswerThree = () => {
    const answers = useAnswers()
    const dispatch = useDispatch()

    const [onExit] = useRiddleExit()

    const onNextClick = () => {
        dispatch(removeModal(RIDDLE_MODAL_ANSWER_THREE))
        dispatch(
            addModal({
                id: RIDDLE_MODAL_QUESTION_FOUR,
                children: <RiddlesModalQuestionFour />,
                hideLogo: true,
                hideCross: true,
                exitOnClickOutside: true,
                exitAction: onExit,
            })
        )
    }
    return (
        <StyledRiddle>
            <Pagination
                riddleNumber={3}
                state="answer"
                prevModalId={RIDDLE_MODAL_QUESTION_THREE}
                prevModalChildren={<RiddlesModalQuestionThree />}
                currentModalId={RIDDLE_MODAL_ANSWER_THREE}
                nextModalId={RIDDLE_MODAL_QUESTION_FOUR}
                nextModalChildren={<RiddlesModalQuestionFour />}
            />
            <MathJax.Provider options={{ messageStyle: "none", tex2jax: { preview: "none" } }}>
                <div className="marginTop">
                    <StyledRiddleTitle type="answer">
                        Riddles - How good is your intuition when it comes to probabilities?
                    </StyledRiddleTitle>
                </div>
                <div className="marginTop">
                    <StyledH3 weight={700}>Boxes and Balls II</StyledH3>
                </div>

                <div className="resultBox marginTop">
                    <div>Your estimate: {answers[3]}%</div>
                    <div className="resultString">
                        Actual answer: <StyledBodyMain weight={700}>42%</StyledBodyMain>
                    </div>
                    <div>that it’s Box A (with mostly white balls).</div>
                </div>

                <div className="marginTop marginBottom">
                    <StyledBodyMain weight={700}>How do we get to this answer?</StyledBodyMain>
                </div>

                <div>
                    <p>
                        Using{" "}
                        <a
                            href="https://www.wikiwand.com/en/Bayes%27_theorem"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Bayes’ theorem
                        </a>{" "}
                        and binomial distribution (like in the previous riddle) plus{" "}
                        <a
                            href="https://www.wikiwand.com/en/Set_theory"
                            target="_blank"
                            rel="noreferrer"
                        >
                            set theory
                        </a>
                        .
                    </p>
                </div>
                <div className="marginTop marginBottom">
                    <StyledBodyMain weight={700} color="grey">
                        Full calculation:
                    </StyledBodyMain>
                </div>

                <MathJax.Node formula={"P(said\\;white\\;|\\;ball\\;white) = 100%"} />
                <MathJax.Node formula={"P(said\\;black\\;|\\;ball\\;black) = 80%"} />
                <MathJax.Node formula={"P(Box\\;A) = P(Box\\;B) =  \\frac{1}{2}"} />
                <MathJax.Node formula={"P(said\\;white\\;|\\;Box\\;A)"} />
                <MathJax.Node formula={"="} />
                <div className="overflowAuto">
                    <MathJax.Node
                        formula={
                            "P(said\\;white\\;|\\;ball\\;white) * P(ball\\;white\\;|\\;Box\\;A) +"
                        }
                    />
                    <MathJax.Node
                        formula={
                            "+ P(said\\;white\\;|\\;ball\\;black) * P(ball\\;black\\;|\\;Box\\;A) "
                        }
                    />
                    <MathJax.Node formula={"="} />
                    <MathJax.Node
                        formula={"100\\% * \\frac{2}{3} + 20\\% * \\frac{1}{3} = \\frac{2.2}{3}"}
                    />

                    <MathJax.Node formula={"P(said\\;white\\;|\\;Box\\;B)"} />
                    <MathJax.Node formula={"="} />
                    <MathJax.Node
                        formula={
                            "P(said\\;white\\;|\\;ball\\;white) * P(ball\\;white\\;|\\;Box\\;B) + "
                        }
                    />
                    <MathJax.Node
                        formula={
                            "+P(said\\;white\\;|\\;ball\\;black) * P(ball\\;black\\;|\\;Box\\;B)"
                        }
                    />
                    <MathJax.Node formula={"="} />
                    <MathJax.Node
                        formula={"100\\% * \\frac{1}{3} + 20\\% * \\frac{2}{3} = \\frac{1.4}{3}"}
                    />

                    <MathJax.Node formula={"P(Box\\;A\\;|\\;30\\;of\\;50\\;said\\;white)"} />
                    <MathJax.Node formula={"="} />
                    <MathJax.Node
                        formula={
                            "\\frac{\\begin{pmatrix} 50  \\\\ 30 \\end{pmatrix}   (P(said\\;white\\;|\\;Box\\;A))^{30}   (P(said\\;black\\;|\\;Box\\;A))^{20}   P(Box\\;A)}    {\\begin{pmatrix} 50  \\\\ 30 \\end{pmatrix}       (P(said\\;white\\;|\\;Box\\;A))^{30}   (P(said\\;black\\;|\\;Box\\;A))^{20}   P(Box\\;A) + }"
                        }
                    />
                    <MathJax.Node
                        formula={
                            "+ \\begin{pmatrix} 50  \\\\ 30 \\end{pmatrix}       (P(said\\;white\\;|\\;Box\\;B))^{30}   (P(said\\;black\\;|\\;Box\\;B))^{20}   P(Box\\;B) "
                        }
                    />
                    <MathJax.Node formula={"="} />
                    <MathJax.Node
                        formula={
                            "\\frac{(\\frac{2.2}{3})^{30}   (\\frac{0.8}{3})^{20}}   {(\\frac{2.2}{3})^{30}   (\\frac{0.8}{3})^{20}   +   (\\frac{1.4}{3})^{30}   (\\frac{1.6}{3})^{20}}"
                        }
                    />
                </div>
                <MathJax.Node formula={"="} />
                <MathJax.Node formula={"\\frac{1}    {1 +  (\\frac{1.4}{2.2})^{30}  2^{20}}"} />
                <MathJax.Node formula={"="} />
                <MathJax.Node formula={"42\\%"} />

                <ShareWithButtons
                    twitterTitle="How's your intuition when it comes to probability? Find out with these 4 riddles. @Rootclaim beats intuition."
                    onSubmit={onNextClick}
                />
            </MathJax.Provider>
        </StyledRiddle>
    )
}

export default RiddlesModalAnswerThree
