import styled from "styled-components"

export const StyledButton = styled.div`
    apperance: none;
    align-items: baseline;
    background-color: transparent;
    border: none;
    display: inline-flex;
    padding: 0;

    &:hover {
        cursor: pointer;
    }

    button {
        margin: 0;
        min-width: unset;

        &:focus {
            outline: none;
        }
    }
`

export const StyledModal = styled.div`
    .formRow {
        margin-bottom: 15px;

        input {
            padding: 10px 18px;
            width: 100%;
        }
    }

    .formAction {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        > * {
            margin: 0 10px;
        }
    }

    @media (max-width: 575px) {
        padding: 20px 0 30px;

        @media not all and (min-resolution: 0.001dpcm) {
            padding-bottom: 140px;
        }
    }
`
